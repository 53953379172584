import Vue from 'vue'
import Router from 'vue-router'

import store from '../store/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '',
            component: () => import('../layouts/main/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import('@/views/pages/Home/Home.vue'),
                    alias: '/index.html',
                },
                {
                    path: '/wills/pipeline',
                    name: 'Pipeline',
                    component: () =>
                        import('@/views/pages/Wills/Pipeline/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/pipeline/to_approve',
                    name: 'willsToApprove',
                    component: () =>
                        import('@/views/pages/Wills/ToApprove/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', url: '/wills/pipeline' },
                            { title: 'To Approve', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/pipeline/on_hold',
                    name: 'willsOnHold',
                    component: () =>
                        import('@/views/pages/Wills/OnHold/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', url: '/wills/pipeline' },
                            { title: 'On Hold', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/pipeline/to_print',
                    name: 'willsToPrint',
                    component: () =>
                        import('@/views/pages/Wills/ToPrint/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', url: '/wills/pipeline' },
                            { title: 'To Print', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/pipeline/to_check',
                    name: 'willsToCheck',
                    component: () =>
                        import('@/views/pages/Wills/ToCheck/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', url: '/wills/pipeline' },
                            { title: 'To Check', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/pipeline/to_contact',
                    name: 'willsToContact',
                    component: () =>
                        import('@/views/pages/Wills/ToContact/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Pipeline', url: '/wills/pipeline' },
                            { title: 'To Contact', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/will/:id',
                    name: 'will',
                    component: () =>
                        import('@/views/pages/Wills/Will/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', url: '/wills/all' },
                            { title: 'Will', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/wills/all',
                    redirect: '/wills/all/1',
                },
                {
                    path: '/wills/all/:page',
                    name: 'wills',
                    component: () =>
                        import('@/views/pages/Wills/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Wills',
                    },
                },
                {
                    path: '/users/all',
                    redirect: '/users/all/1',
                },
                {
                    path: '/users/all/:page',
                    name: 'users',
                    component: () =>
                        import('@/views/pages/Users/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Users',
                    },
                },
                {
                    path: '/users/staff',
                    name: 'All',
                    component: () =>
                        import('@/views/pages/Users/Staff/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Staff', active: true },
                        ],
                        pageTitle: 'Users',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/users/brokers',
                    redirect: '/users/brokers/1',
                },
                {
                    path: '/users/brokers/:page',
                    name: 'UsersBrokers',
                    component: () =>
                        import('@/views/pages/Users/Brokers/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Brokers', active: true },
                        ],
                        pageTitle: 'Users',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/user/:id',
                    name: 'user',
                    component: () =>
                        import('@/views/pages/Users/User/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', url: '/users/all' },
                            { title: 'User', active: true },
                        ],
                        pageTitle: 'Users',
                    },
                },
                {
                    path: '/user/staff/:id',
                    name: 'user_staff',
                    component: () =>
                        import('@/views/pages/Users/UserStaff/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Staff', url: '/users/staff' },
                            { title: 'User', active: true },
                        ],
                        pageTitle: 'Users',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/advice/:page',
                    name: 'advice',
                    component: () => import('@/views/pages/Advice/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Advice', active: true },
                        ],
                        pageTitle: 'Advice',
                    },
                },
                {
                    path: '/advice/search/:query',
                    name: 'adviceSearch',
                    component: () => import('@/views/pages/Advice/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Advice', active: true },
                        ],
                        pageTitle: 'Advice',
                    },
                },

                {
                    path: '/brokers/:page',
                    name: 'Brokers',
                    component: () =>
                        import('@/views/pages/Brokers/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Brokers',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/broker/:pk',
                    name: 'broker',
                    component: () =>
                        import('@/views/pages/Brokers/Broker/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', url: '/brokers/1' },
                            { title: 'Broker', active: true },
                        ],
                        pageTitle: 'Brokers',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/broker/edit/:pk',
                    name: 'brokerEdit',
                    component: () =>
                        import('@/views/pages/Brokers/Broker/Edit/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', url: '/brokers/1' },
                            { title: 'Broker', active: true },
                        ],
                        pageTitle: 'Broker Edit',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/clients/all/:page',
                    name: 'Clients',
                    component: () =>
                        import('@/views/pages/Brokers/Clients/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Clients', active: true },
                        ],
                        pageTitle: 'Clients',
                    },
                },
                {
                    path: '/clients/batch',
                    name: 'BatchClientUploads',
                    component: () =>
                        import('@/views/pages/Brokers/Batch/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Clients', active: true },
                        ],
                        pageTitle: 'Batch Clients',
                    },
                },
                {
                    path: '/email',
                    name: 'email',
                    component: () => import('@/views/pages/Email/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Email',
                    },
                },
                {
                    path: '/email/:userId/:userAddress',
                    name: 'emailUser',
                    component: () => import('@/views/pages/Email/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Email User',
                    },
                },
                {
                    path: '/finance/invoices/:page/:filter',
                    name: 'invoicesPageFilter',
                    component: () =>
                        import('@/views/pages/Invoices/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Invoices',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/finance/invoices/:page',
                    name: 'invoices',
                    component: () =>
                        import('@/views/pages/Invoices/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', active: true },
                        ],
                        pageTitle: 'Invoices',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/finance/invoices',
                    redirect: '/finance/invoices/1',
                },
                {
                    path: '/finance/invoice/:pk',
                    name: 'invoice',
                    component: () =>
                        import('@/views/pages/Invoices/Invoice/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'All', url: '/finance/invoices' },
                            { title: 'Invoice', active: true },
                        ],
                        pageTitle: 'Invoice',
                    },
                },
                {
                    path: '/finance/commissions',
                    name: 'Commissions',
                    component: () =>
                        import('@/views/pages/Commissions/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Commissions', active: true },
                        ],
                        pageTitle: 'Commissions',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/finance/commissions/create',
                    name: 'CommissionsCreate',
                    component: () =>
                        import(
                            '@/views/pages/Commissions/Commission/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Commission', active: true },
                        ],
                        pageTitle: 'Commission',
                    },
                },
                {
                    path: '/finance/payments',
                    name: 'PaymentsFinance',
                    component: () =>
                        import('@/views/pages/Payments/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Payments', active: true },
                        ],
                        pageTitle: 'Payments',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/basic',
                    name: 'Reports',
                    component: () =>
                        import('@/views/pages/Reports/Basic/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Basic', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/overview',
                    name: 'ReportsOverview',
                    component: () =>
                        import('@/views/pages/Reports/Overview/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Overview', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/share',
                    name: 'ReportsShare',
                    component: () =>
                        import('@/views/pages/Reports/Share/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Share', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/payments',
                    name: 'Payments',
                    component: () =>
                        import('@/views/pages/Reports/Payments/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Payments', active: true },
                        ],
                        pageTitle: 'Payments',
                        is_staff_admin: true,
                    },
                },
                {
                    path: '/reports/brokers/accounts',
                    name: 'ReportsBrokersAccounts',
                    component: () =>
                        import(
                            '@/views/pages/Reports/Brokers/Accounts/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Brokers', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/brokers/plans',
                    name: 'ReportsBrokersPlans',
                    component: () =>
                        import('@/views/pages/Reports/Brokers/Plans/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Brokers', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/reports/brokers/servers',
                    name: 'ReportsBrokersServers',
                    component: () =>
                        import(
                            '@/views/pages/Reports/Brokers/Server/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Brokers', active: true },
                        ],
                        pageTitle: 'Reports',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system',
                    name: 'System',
                    component: () => import('@/views/pages/System/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', active: true },
                        ],
                        pageTitle: 'System',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/user',
                    name: 'CommunicationUser',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/indexUser.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Communication User', active: true },
                        ],
                        pageTitle: 'Communication User',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/broker',
                    name: 'CommunicationBroker',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/indexBroker.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Communication Broker', active: true },
                        ],
                        pageTitle: 'Communication Broker',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/emails',
                    name: 'EmailsAll',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Emails/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Emails All', active: true },
                        ],
                        pageTitle: 'Emails All',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/emails/email/:pk',
                    name: 'Email',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Emails/Email/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Email All', url: '/system/communication/emails' },
                            { title: 'Email', active: true },
                        ],
                        pageTitle: 'Email',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/sms',
                    name: 'SmsAll',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Sms/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Sms All', active: true },
                        ],
                        pageTitle: 'Sms All',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/broker_logs',
                    name: 'BrokerLogs',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Logs/Broker/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Broker Logs', active: true },
                        ],
                        pageTitle: 'Broker Logs',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/communication/system_logs',
                    name: 'SystemLogs',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Logs/System/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'System Logs', active: true },
                        ],
                        pageTitle: 'System Logs',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/sms/text/:pk',
                    name: 'Text',
                    component: () =>
                        import(
                            '@/views/pages/System/Communication/Sms/Text/index.vue'
                        ),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            {
                                title: 'Sms All',
                                url: '/system/communication/sms',
                            },
                            { title: 'Text', active: true },
                        ],
                        pageTitle: 'Text',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/scheduled_tasks',
                    name: 'Sms',
                    component: () =>
                        import('@/views/pages/System/Tasks/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Tasks', active: true },
                        ],
                        pageTitle: 'Tasks',
                        is_staff_admin: false,
                    },
                },
                {
                    path: '/system/coupons/all',
                    redirect: '/system/coupons/all/1',
                },
                {
                    path: '/system/coupons/all/:page',
                    name: 'coupons',
                    component: () =>
                        import('@/views/pages/System/Coupons/All/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Coupons', active: true },
                        ],
                        pageTitle: 'Coupons',
                    },
                },
                {
                    path: '/system/coupons_batch/all',
                    redirect: '/system/coupons_batch/all/1',
                },
                {
                    path: '/system/coupons_batch/all/:page',
                    name: 'couponsBatch',
                    component: () =>
                        import('@/views/pages/System/Coupons/Batch/index.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'System', url: '/system' },
                            { title: 'Coupons Printed', active: true },
                        ],
                        pageTitle: 'Coupons Printed',
                    },
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    component: () =>
                        import('@/views/pages/Users/UserStaff/Profile.vue'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'Profile', active: true },
                        ],
                        pageTitle: 'Profile',
                    },
                },
            ],
        },
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'page-login',
                    component: () => import('@/views/pages/Login.vue'),
                },
                {
                    path: '/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                },
            ],
        },

        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404',
        },
    ],
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    let user = store.getters.user

    if (to.matched.some((record) => record.meta.is_staff_admin)) {
        if (user && user.is_staff_admin == 1) {
            next()
        } else {
            next({ name: 'home' })
        }
    } else if (to.matched.some((record) => record.meta.is_staff)) {
        if (user.is_staff == 1) {
            next()
        } else {
            next({ name: 'home' })
        }
    } else if (to.matched.some((record) => record.meta.is_superuser)) {
        if (user.is_superuser == 1) {
            next()
        } else {
            next({ name: 'home' })
        }
    } else {
        next()
    }
})

export default router
